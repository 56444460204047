import subscriptionApiService from '@/services/api/subscriptions-api-service';
import { defineStore } from 'pinia';

export const useSubscriptionsStore = defineStore('subscriptions-store', {
  state: () => ({
    data: [],
    isLoading: false,
  }),
  actions: {
    async fetchSubscriptions(params = {}) {
      this.isLoading = true;
      try {
        const res = await subscriptionApiService.listSubscriptions(params);
        this.data = res?.items || [];
        return res;
      } finally {
        this.isLoading = false;
      }
    },
    async addSubscription(payload) {
      this.isLoading = true;
      try {
        const res = await subscriptionApiService.createSubscription(payload);
        this.data.push(res);
        return res;
      } finally {
        this.isLoading = false;
      }
    },
    async editSubscription(id, payload) {
      this.isLoading = true;
      try {
        const res = await subscriptionApiService.updateSubscription(id, payload);
        const index = this.data.findIndex((item) => item.id === id);
        this.data.splice(index, 1, res);
        return res;
      } finally {
        this.isLoading = false;
      }
    },
    async removeSubscription(id, payload = {}) {
      this.isLoading = true;
      try {
        const res = await subscriptionApiService.deleteSubscription(id, payload);
        this.data = this.data.filter((item) => item.id !== id);
        return res;
      } finally {
        this.isLoading = false;
      }
    },
  },
});
