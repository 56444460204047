/**
 * Helper to get the role and mode of the user of a superdoc
 * @param {Array[string]} collaborators
 * @param {Object} superdocCreator Object containing the id and email of the superdoc creator
 * @param {string} currentUserEmail The email of the current user
 * @returns {Object} Object containing the role and mode of the user
 */
export const getRoleAndDocumentMode = (collaborators, superdocCreator, currentUserEmail) => {
  const { email: creatorEmail } = superdocCreator;

  let initialRole = { role: 'viewer', mode: 'viewing' };

  // Check if currentUserEmail exists in editors array
  const isEditor = collaborators.editors?.some((editor) => editor.email === currentUserEmail);
  if (isEditor) initialRole = { role: 'editor', mode: 'editing' };

  // Check if currentUserEmail exists in suggesters array
  const isSuggester = collaborators.suggesters?.some(
    (suggester) => suggester.email === currentUserEmail,
  );
  if (isSuggester) initialRole = { role: 'suggester', mode: 'suggesting' };

  // Check if user is creator
  const isCreator = currentUserEmail === creatorEmail;

  const role = isCreator ? 'editor' : initialRole.role;
  const mode = isCreator ? 'editing' : initialRole.mode;

  return { role, mode };
};
