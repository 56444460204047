import { toRef, ref, isRef } from 'vue';


/**
 * Composable to handle an active agreement.
 * @param {Object} params - Parameter object
 * @param {Object} params.agreementData Agreement data object
 * @returns {Object} Agreement composable
 */
export function useAgreement() {
  const id = ref(null);
  const templateGroupId = ref(null);
  const responseData = ref({});
  const title = ref(null);
  const contentJson = ref({});
  const customInputs = ref({});
  const signerInputs = ref([]);
  const hasChanged = ref(false);

  const signers = ref([]);
  const isPublicLink = ref(false);
  const isSetSigningOrder = ref(false);
  const inputValueMap = {};
  const sections = ref([]);
  const subheader = ref(null);
  const branding = ref({});
  const defaultInputsState = ref({});

  const creatorEmail = ref(null);
  const creatorId = ref(null);

  const getAgreementInputs = () => customInputs.value.custominputsitems;

  /**
   * Connect a new agreement to the superdoc.
   * @param {Object} agreementData The agreement data from the server
   * @param {string} creatorEmail The email of the agreement creator
   * @param {string} creatorId The id of the agreement creator
   */
  const newAgreement = (agreementData) => {
    id.value = agreementData.newagreementid;
    templateGroupId.value = agreementData.template_group_id;
    responseData.value = agreementData;
    title.value = agreementData.agreementtitle;
    contentJson.value = agreementData.agreementcontentjson;
    customInputs.value = agreementData.agreementcustominputjson;

    hasChanged.value = false;
    creatorEmail.value = agreementData.creatoremail;
    creatorId.value = agreementData.creatorid;

    fields.init(contentJson, customInputs);
  };

  const updateResponseData = (agreementData) => {
    responseData.value = agreementData;
  };

  const setAgreementInputs = (inputs) => {
    customInputs.value.custominputsitems = inputs;
  };
  const setSignerInputs = (inputs) => {
    signerInputs.value = inputs;
  }

  const setSections = (updatedSections) => {
    sections.value = updatedSections.map((section) => {
      return {
        sectionicon: section.icon,
        sectioniconpack: section.iconpack,
        sectionid: section.id,
        sectiontitle: section.title,
        sectionvalidations: section.validations || [],
        sectionitems: section.inputs,
      }
    });
  };

  const getAuthMode = () => {
    if (isPublicLink.value) return 'PUBLIC';
    return 'EMAILS';
  }

  /**
   * Trigger when any change happens to the agreement.
   * Updates the hasChanged flag in place.
   * @returns {void}
   */
  const onChange = () => {
    hasChanged.value = true;
  };

  // Leaving this here - next PR will be using it
  const fields = useAgreementFields(onChange);

  return {
    id,
    templateGroupId,
    responseData,
    title,
    contentJson,
    customInputs,
    signerInputs,
    hasChanged,
    signers,
    isPublicLink,
    isSetSigningOrder,
    inputValueMap,
    sections,
    subheader,
    branding,
    defaultInputsState,
    creatorEmail,
    creatorId,
    getAgreementInputs,
    onChange,
    newAgreement,
    setAgreementInputs,
    setSignerInputs,
    setSections,
    getAuthMode,
    fields,
    updateResponseData,
  }
};


/**
 * Composable to handle fields of an active agreement.
 * Probably should be used internally by useAgreement composable, but can be used standalone.
 * @param {function} onChange Function to trigger when any change happens to the agreement
 * @returns {useAgreementFields} Fields composable
 */
export function useAgreementFields(onChange) {

  let sectionContent = null;
  let agreementInputs = null;

  const inputs = ref([]);
  const inputToSectionMap = {};

  const init = (contentJson, aoInputs) => {
    sectionContent = isRef(contentJson) ? contentJson : toRef(contentJson);
    agreementInputs = toRef(aoInputs);

    sectionContent.value.sections.forEach((section, sectionIndex) => {
      const { sectionitems } = section;
      sectionitems?.forEach((item) => {
        inputs.value.push(item);
        inputToSectionMap[item.itemid] = sectionIndex;
      });
    });
  };


  /**
   * Remove a field from the agreement, either signer field or agreement input field.
   * @param {string} fieldId
   * @returns {void}
   */
  const removeField = (fieldId) => {
    const sectionIndex = inputToSectionMap[fieldId];
    if (sectionIndex) removeSignerField(fieldId);

    const aoInput = agreementInputs.value.custominputsitems?.find((input) => input.itemid === fieldId);
    if (aoInput) removeAgreementInput(fieldId);

    if (sectionIndex || aoInput) onChange();
  };


  /**
   * Remove a signer field from the agreement content
   * @param {string} fieldId
   * @returns {void} 
   */
  const removeSignerField = (fieldId) => {
    // Remove the field from the content section
    const sectionIndex = inputToSectionMap[fieldId];
    const section = sectionContent.value.sections[sectionIndex];
    const updatedSectionItems = section.sectionitems.filter((item) => item.itemid !== fieldId);

    // Update the agreement content in place
    sectionContent.value.sections[sectionIndex] = {
      ...section,
      sectionitems: updatedSectionItems,
    };
  };


  /**
   * Remove an agreement input field.
   * @param {string} fieldId 
   * @returns {void}
   */
  const removeAgreementInput = (fieldId) => {
    agreementInputs.value.custominputsitems = agreementInputs.value.custominputsitems.filter((input) => input.itemid !== fieldId);
    inputs.value = inputs.value.filter((input) => input.itemid !== fieldId);
  };


  // Exposed state and methods
  return {
    inputs,
    agreementInputs,

    init,
    removeField,
  }
};
