/**
 * Convert a Blob to a base64 string
 * @param {Blob} blob The blob to convert
 * @returns {Promise<string>} The resulting base64 string
 */
export const convertBlobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
          const base64String = reader.result.split(",")[1]; // Remove the data URL prefix
          resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
  });
};

/**
 * Convert a base64 file to a Blob
 * @param {string} base64String 
 * @returns {Blob} The resulting blob
 */
export const convertBase64ToBlob = (base64String, mimeType) => {
  const byteCharacters = atob(base64String);
  const byteNumbers = Array.from(byteCharacters).map((char) => char.charCodeAt(0));
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
};

/**
 * 
 * @param {object} attachment 
 * @returns {string} The base64 string of the attachment
 */
export const getAttachmentBase64 = async (attachment) => {
  // get preview
  const previewPath = attachment.referenceattachmentpreviewgcspath;
  const previewFileName = 'Dem2';
  const newFileUrl = `${window.location.origin}/agreelinkattachmentthumbnail/${previewPath}/${previewFileName}`;
  const previewBlob = await fetch(newFileUrl).then((res) => res.blob());
  const previewBase64 = await convertBlobToBase64(previewBlob);

  return 'data:image/png;base64,' + previewBase64;
};