import { ref } from 'vue';

export function useAgreementLink() {

  const id = ref(null);

  return {
    id,
  };
};
