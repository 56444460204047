/**
 * Converts a filter object to a query string
 * @param {Object} filter The filter object to convert to a query string
 * @returns {string} The resulting query string
 */
export const createQueryString = (filter) => {
  return Object.entries(filter)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return `${encodeURIComponent(key)}=${value.join(',')}`; // Do not encode commas
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');
};
