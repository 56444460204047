import { ref } from 'vue';
import { getRoleAndDocumentMode } from './helpers';
import { useAgreement } from './use-agreement';
import { useAgreementLink } from './use-agreement-link';

export function useSuperdoc(superdocData = {}) {
  const id = superdocData.id;
  const name = ref(superdocData.name);
  const state = ref(superdocData.state);
  const organizationId = superdocData.organization_id;
  const createdBy = superdocData.created_by;
  const createdAt = superdocData.created_at;
  const updatedBy = superdocData.updated_by;
  const updatedAt = superdocData.updated_at;
  const files = superdocData.files || [];
  const collaborators = superdocData.collaborators || {};
  const isInternal = superdocData.is_internal;

  // Agreement and link
  const agreement = useAgreement();
  const link = useAgreementLink();

  /** Optional agreement  */
  const connectAgreement = (agreementData) => {
    agreement.newAgreement(agreementData);
  };

  const updateAgreementResponseData = (agreementData) => {
    agreement.updateResponseData(agreementData);
  };

  const getFiles = () => files;
  const setInitialized = () => {
    files.forEach((currentFile) => {
      delete currentFile.data;
      delete currentFile.isNewFile;
    });
  };

  /**
   * Get the document mode based on the current user and superdoc.
   * @param {string} userEmail The current user
   * @returns {string} The document mode
   */
  const getDocumentMode = (userEmail) =>
    getRoleAndDocumentMode(collaborators, createdBy, userEmail).mode;

  /**
   * Get the role based on the current user and superdoc.
   * @param {string} userEmail The current user
   * @returns {string} The role
   */
  const getRole = (userEmail) => getRoleAndDocumentMode(collaborators, createdBy, userEmail).role;

  const getState = () => {
    return {
      id,
      name: name.value,
      state: state.value,
      organizationId,
      createdBy,
      createdAt,
      updatedBy,
      updatedAt,
      files,
      collaborators,
      agreement,
      link,
    };
  };

  const init = () => {
    link.getParent = getState;
    agreement.getParent = getState;
  };
  init();

  return {
    id,
    name: name.value,
    state: state.value,
    organizationId,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    files,
    collaborators,
    agreement,
    link,
    isInternal,

    setInitialized,
    getFiles,
    connectAgreement,
    getDocumentMode,
    getRole,
    updateAgreementResponseData,
  };
}
