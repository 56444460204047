import { useHarbourStore } from '@/stores/harbour-store';
import { useLibraryStore } from '@/stores/library-store';
import { DialogProgrammatic as Dialog } from 'buefy';
import nlp from 'compromise';
import { defineStore } from 'pinia';
import Vue from 'vue';
import GridFilterPopupHead from '../components/Other/GridFilterPopupHead.vue';

export const useColumnStore = defineStore('column', {
  state: () => ({
    harbourStore: useHarbourStore(),
    libraryStore: useLibraryStore(),

    artisanalOptions: [
      { artisanal_id: 'agreement_parties', value: 'Agreement parties', source: 'artisanal' },
      { artisanal_id: 'assignment', value: 'Assignment', source: 'artisanal' },
      { artisanal_id: 'contract_amount', value: 'Contract amount', source: 'artisanal' },
      { artisanal_id: 'effective_date', value: 'Effective date', source: 'artisanal' },
      { artisanal_id: 'expiration_date', value: 'Expiration date', source: 'artisanal' },
      { artisanal_id: 'governing_law', value: 'Governing law', source: 'artisanal' },
      { artisanal_id: 'indeminifaction', value: 'Indemnification', source: 'artisanal' },
      { artisanal_id: 'limit_of_liability', value: 'Limit of liability', source: 'artisanal' },
      { artisanal_id: 'notice_period', value: 'Notice period', source: 'artisanal' },
      { artisanal_id: 'payment_due_date', value: 'Payment due date', source: 'artisanal' },
      { artisanal_id: 'renewal_term_length', value: 'Renewal term length', source: 'artisanal' },
      { artisanal_id: 'termination_language', value: 'Termination language', source: 'artisanal' },
    ],

    gridApi: null,
    columnApi: null,

    blankColumnIcon: 'fal fa-user-edit',
    aiColumnIcon: 'fal fa-sparkles',

    aiColumns: new Set(),
    userGeneratedColumns: new Set(),
    acceptedTypes: ['ai', 'blank'],

    aiColumnsMaxAssetsBatch: 20,

    initialColumnDefs: [],
    workingColumnDefs: [],

    restoreState: [],
    stateToSave: {},
    userColumnsToSave: [],

    // Status updates
    pendingExtractions: [],
    currentStatus: null,
    misssingDocType: [],
    MAX_RETRIES: 10,

    // Preview extraction
    previewExtraction: null,

    // Custom expiration filter
    expirationTimeframe: null,
    customExpirationFilterParams: {
      filters: [
        {
          filter: 'ExpiresFilter',
        },
        {
          filter: 'agDateColumnFilter',
          filterParams: {
            filterChangedCallback: () => {
              console.debug('** FILTER CHANGE');
            },
            filterOptions: [
              {
                displayKey: 'beforeDate',
                displayName: 'Before date',
                predicate: ([filterValue], cellValue) =>
                  cellValue == null || cellValue < filterValue,
              },
              {
                displayKey: 'afterDate',
                displayName: 'After date',
                predicate: ([filterValue], cellValue) =>
                  cellValue == null || cellValue < filterValue,
              },
            ],
          },
        },
      ],
    },
  }),

  getters: {
    getColumnDefs: (state) => state.workingColumnDefs,
    getMenuColDefs: (state) =>
      state.workingColumnDefs.filter((column) => !column.suppressColumnsToolPanel),
    aiColumnDefaults: (state) => {
      return {
        ai: true,
        headerClass: 'ai-column',
        cellClass: 'ai-cell folders-grid-cell',
        resizable: state.libraryStore.hasEditPermissions,
        suppressMovable: !state.libraryStore.hasEditPermissions,
        suppressHeaderKeyboardEvent: () => true,
        suppressKeyboardEvent: () => true,
        minWidth: 150,
      };
    },
    blankColumnDefinition: (state) => {
      return {
        field: 'Blank',
        editable: state.libraryStore.hasEditPermissions,
        resizable: state.libraryStore.hasEditPermissions,
        userColumn: true,
        headerClass: 'user-column',
        cellClass: 'hrbr-grid-cell folders-grid-cell',
        cellEditor: 'ColumnEditor',
        cellRenderer: 'AiCellRenderer',
        suppressMovable: !state.libraryStore.hasEditPermissions,
        minWidth: 150,
      };
    },
  },
  actions: {
    generateColumnId(name) {
      const now = Date.now();
      const cleanName = this.sanitizeColumnName(name);
      const uniqueId = crypto.randomUUID();
      return `${uniqueId}_${cleanName.toLowerCase()}_${now}`;
    },

    sanitizeColumnName(name) {
      return name.replace(/[^a-zA-Z0-9]/g, '');
    },

    // Filter value getter for AI columns
    aiColumnFilterValueGetter(params) {
      const column = params?.column;
      const colId = column?.colId;
      const type = column?.colDef.columnType;
      const value = params?.data[colId];

      if (Array.isArray(value?.extraction)) return value?.extraction.join(', ');
      else if (type === 'date') return this.harbourStore.formatDateWithoutTime(value?.extraction);
      return value?.extraction;
    },

    mapTextForFuzzyComparison(text) {
      return text.trim().toLowerCase();
    },

    getNounMatches(text) {
      const doc = nlp(text);
      const nouns = new Set(doc.match('#Noun').out('array').map(this.mapTextForFuzzyComparison));
      return Array.from(nouns);
    },

    isFileUnderEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }

      const sortedArr1 = arr1.slice().sort();
      const sortedArr2 = arr2.slice().sort();

      for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
          return false;
        }
      }

      return true;
    },

    generateUserColumn(columnSchema) {
      let newColumn = { ...this.blankColumnDefinition };
      newColumn.field = columnSchema.field;
      newColumn.headerName = columnSchema.headerName;
      newColumn.originalName = columnSchema.originalName;
      newColumn.fileUnder = this.getNounMatches(columnSchema.headerName);
      newColumn.cellEditorParams = {
        callback: this.updateCellValue,
      };

      newColumn.comparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
        const val1 = valueA?.extraction;
        const val2 = valueB?.extraction;

        // Group null and 'None' values
        if (val1 === undefined || val1 === null || val1 === 'None') {
          return isDescending ? -1 : 1;
        }
        if (val2 === undefined || val2 === null || val2 === 'None') {
          return isDescending ? 1 : -1;
        }

        // Add a custom comparator for dates
        if (newColumn.field.includes('date')) {
          // Parse the date strings and compare
          const parsedDate1 = new Date(val1);
          const parsedDate2 = new Date(val2);

          if (isNaN(parsedDate1) || isNaN(parsedDate2)) {
            return 0;
          }

          // Compare the parsed date objects
          if (parsedDate1 < parsedDate2) {
            return -1;
          } else if (parsedDate1 > parsedDate2) {
            return 1;
          } else {
            return 0;
          }
        } else {
          return val1 > val2 ? 1 : -1;
        }
      };

      // If this is an AI column, we have different settings
      if (columnSchema.artisanal_id || columnSchema.columnType === 'ai') {
        newColumn = { ...newColumn, ...this.aiColumnDefaults };
        newColumn.artisanal_id = columnSchema.artisanal_id;
        newColumn.filterValueGetter = (params) => this.aiColumnFilterValueGetter(params);

        if (this.isFileUnderEqual(newColumn.fileUnder, ['expiration', 'date'])) {
          newColumn.filterParams = this.customExpirationFilterParams;
          newColumn.filterValueGetter = null;
        }
        if (newColumn.headerName.toLowerCase().includes('date')) newColumn.columnType = 'date';
        this.aiColumns.add(newColumn.field);
      } else {
        newColumn.filterValueGetter = (params) => this.aiColumnFilterValueGetter(params);
      }

      // Track user generated columns
      this.userGeneratedColumns.add(newColumn.field);
      return newColumn;
    },

    addBlankColumn() {
      const callback = (columnSchema) => {
        const newColumn = this.generateUserColumn(columnSchema);
        this.addUserColumnToGrid(newColumn);
        this.harbourStore.sendColumnChangeSync({ changeType: 'add-column', newColumn });
      };
      this.promptForColumnName(callback);
    },

    createColumnFromPrompt(prompt) {
      if (!prompt.value) return;
      const promptValue = prompt.value;
      const headerName = this.capitalizeTitle(promptValue);

      let generatedKey = prompt.field;
      if (!prompt.field) generatedKey = this.generateColumnId(headerName);

      const { artisanal_id, columnType } = prompt;
      const columnSchema = {
        field: generatedKey,
        headerName,
        artisanal_id: artisanal_id,
        columnType,
      };

      const newColumn = this.generateUserColumn(columnSchema);
      return newColumn;
    },

    addUserColumnToGrid(newColumn) {
      const oldState = this.gridColumnApi?.getColumnState();
      const gridColumns = this.getColumnDefs;
      const lastColumn = gridColumns.pop();
      gridColumns.push(newColumn);
      gridColumns.push(lastColumn);

      this.refreshColumnDefs(gridColumns);
      this.gridApi.ensureColumnVisible(newColumn.field, 'start');

      const newState = this.gridColumnApi?.getColumnState();
      // Ag Grid apparently does not persist the "hide" state of columns after resetting the column defs
      // Hence we need to restore the column state manually
      const columnsToRestore = oldState.filter((column) => !column.hide);
      newState.forEach((column) => {
        if (columnsToRestore.find((col) => col.colId === column.colId)) {
          column.hide = false;
        }
      });

      this.gridColumnApi.applyColumnState({ state: newState });
    },

    trackPendingExtractions(pendingExtraction) {
      this.pendingExtractions.push(pendingExtraction);
    },

    removePendingExtraction(assetId, key) {
      const index = this.pendingExtractions.findIndex(
        (item) => item.assetId === assetId && item.key === key,
      );
      if (index === -1) return;
      this.pendingExtractions.splice(index, 1);
    },

    stopLoaders() {
      // We have retried enough, anything left over must have an error so we stop it.
      if (!this.pendingExtractions.length) return;

      this.pendingExtractions.forEach((item) => {
        const { assetId, key } = item;
        const asset = this.harbourStore.myAssets.find((asset) => asset.id === assetId);
        if (!asset) return;
        asset[key] = { extraction: null };
      });
      this.pendingExtractions = [];
    },

    async requestAiColumnExtractions(ids, folderId, promptsWithKeys, previewOnly = false) {
      const start = Date.now();
      if (!ids.length) return;
      const options = {
        ai_request: {
          asset_ids: ids,
          prompts: promptsWithKeys,
          preview_only: previewOnly,
          folder_id: folderId,
        },
      };

      // Keep track of the pending extractions - one per prompt per asset
      ids.forEach((assetId) => {
        promptsWithKeys.forEach((prompt) => {
          console.debug('[AI] tracking extraction', assetId, prompt);
          this.pendingExtractions.push({
            assetId,
            key: prompt.key,
          });
        });
      });
      console.debug('[AI] pending extractions', this.pendingExtractions);

      const url = `/vertexai/extract`;
      const result = await Vue.prototype.$harbourData.post(url, options);
      if (result.status !== 200) return;

      console.debug(
        '[AI] extraction request OK. Awaiting tasks...',
        result.data?.results,
        'took',
        Date.now() - start,
        'ms',
      );

      if (!previewOnly) {
        const results = result.data?.results;
        results.forEach((result) => {
          const pendingForThisAsset = this.pendingExtractions.filter((item) => item.assetId);
          pendingForThisAsset.forEach((item) => {
            if (!!item && item.task) item.task = result.task;
          });
        });

        this.checkBackForExtractionUpdates();
      }
      return result;
    },

    async checkBackForExtractionUpdates(retry = 0) {
      // Call this with no params, it will check back on all pending extractions after a delay
      if (this.pendingExtractions.length === 0 || retry > this.MAX_RETRIES) {
        console.debug('[AI] No pending extractions to check back on');
        return;
      }

      if (retry === 0) {
        const delay = 15000;
        console.debug(`[AI] Checking back on pending extractions in ${delay}s...`);
        setTimeout(() => {
          this.checkBackForExtractionUpdates(++retry);
        }, 15000);
        return;
      }

      const endpoint = '/vertexai/check';
      const options = {
        payload: {
          pending_assets: this.pendingExtractions,
          folder_id: this.harbourStore.getNormalizedFolderId(this.harbourStore.currentFolder),
        },
      };
      const result = await Vue.prototype.$harbourData.post(endpoint, options);
      if (result.status !== 200) {
        console.error('[AI] Check back failed', result);
        return;
      }

      // Update the pending extractions
      const updatedAssets = result.data?.assets;
      if (!updatedAssets) return;

      updatedAssets.forEach((updatedAsset) => {
        const pendingKeysForAsset = this.pendingExtractions.filter(
          (item) => item.assetId === updatedAsset.id,
        );
        pendingKeysForAsset.forEach((item) => {
          const { key } = item;
          const extraction = updatedAsset[key];
          if (extraction) {
            const asset = this.harbourStore.myAssets.find((asset) => asset.id === updatedAsset.id);
            asset[key] = extraction;
            this.removePendingExtraction(updatedAsset.id, key);
          }
        });
      });

      if (this.harbourStore.currentFolder === result.data.folder_id) this.gridApi?.refreshCells();
    },

    setPreviewIntoTable(promptsWithKeys) {
      if (!this.previewExtraction) return;

      let assetId;
      try {
        assetId = Object.keys(this.previewExtraction.extraction)[0];
      } catch (e) {
        return;
      }

      const asset = this.harbourStore.myAssets.find((item) => item.id === assetId);
      const key = promptsWithKeys.find(
        (p) => p.value === this.previewExtraction.originalRequest,
      )?.key;
      if (!asset || !key) return;

      asset[key] = this.previewExtraction.extraction[assetId];
      this.gridApi?.refreshCells();
    },

    async processAiColumns(prompts) {
      if (!prompts) return;

      // Ensure location is updated for sync
      this.harbourStore.realtimeUserSync();

      const promptsWithKeys = [];
      const columnPromises = [];
      const delay = 50;
      prompts.forEach((prompt, index) => {
        prompt.columnType = 'ai';
        const newColumn = this.createColumnFromPrompt(prompt);
        this.addUserColumnToGrid(newColumn);
        promptsWithKeys.push({ ...prompt, key: newColumn.field });
        setTimeout(() => {
          columnPromises.push(
            this.harbourStore.sendColumnChangeSync({ changeType: 'add-column', newColumn }),
          );
        }, index * delay);
      });
      this.updateFolderColumnState();

      // Once columns are created, we can request extractions
      const folderId = this.harbourStore.getNormalizedFolderId(this.harbourStore.currentFolder);
      const assetsInFolder = this.harbourStore.myAssets.filter(
        (asset) => asset.folder_id === folderId && asset.file_extension?.toLowerCase() === 'pdf',
      );
      const ids = assetsInFolder.map((asset) => asset.id);
      this.requestAiColumnExtractions(ids, folderId, promptsWithKeys);

      await Promise.all(columnPromises);
      this.setPreviewIntoTable(promptsWithKeys);
    },

    capitalizeTitle(name) {
      if (!name) return null;
      const firstLetter = name.charAt(0).toUpperCase();
      const restOfName = name.slice(1);
      const title = `${firstLetter}${restOfName}`;
      return title;
    },

    promptForColumnName(callback, value = null) {
      const msg = 'Enter a name for the new column';
      const placeholder = 'Column name';
      Dialog.prompt({
        message: msg,
        inputAttrs: {
          value: this.capitalizeTitle(value),
          placeholder,
          maxlength: 300,
          minlength: 1,
        },
        onConfirm: (newColumnName) => {
          const name = this.capitalizeTitle(newColumnName?.trim());
          const key = this.generateColumnId(name);
          const columnSchema = { field: key, headerName: name };
          !!callback && callback(columnSchema);
        },
        onCancel: () => null,
      });
    },

    getDataNode(index) {
      const pdfData = this.getPDFNodes();
      if (!pdfData || !pdfData.length) return null;
      if (index < 0 || index >= pdfData.length) return null;
      const node = pdfData[index];

      if (!node) return null;
      return node;
    },

    getPDFNodes() {
      const data = this.gridApi.gridOptionsService.gridOptions.rowData;
      return data.filter((item) => item.file_extension?.toLowerCase() === 'pdf');
    },

    getPDFAssets() {
      let folderId = this.harbourStore.currentFolder;
      if (folderId === '#home') folderId = this.libraryStore.getHomeFolder;

      return this.harbourStore.myAssets.filter((item) => {
        return item.folder_id === folderId && item.file_extension?.toLowerCase() === 'pdf';
      });
    },

    changeColumnName(key, newColumnName) {
      const existingCol = this.workingColumnDefs.find((column) => column.field === key);
      if (!existingCol) return;

      existingCol.headerName = newColumnName;

      this.refreshColumnDefs(this.workingColumnDefs);
      this.gridApi.refreshHeader();
      this.harbourStore.sendColumnChangeSync({ changeType: 'column-title' });
    },

    async sendCellUpdateToServer(column, asset_id, value, title) {
      const folderId = this.harbourStore.getNormalizedFolderId(this.harbourStore.currentFolder);

      const options = {
        cell_update_request: {
          column_id: column,
          column_title: title,
          reference_id: asset_id,
          parent_id: folderId,
          value,
        },
      };
      await Vue.prototype.$harbourData.post('/realtime/cell-update', options);
    },

    async updateCellValue(params) {
      const { column, originalValue, value, rowId, columnTitle } = params;
      return this.sendCellUpdateToServer(column, rowId, value, columnTitle);
    },

    async updateCellLock({ lock, column_id, parent_id, asset_id }) {
      return Vue.prototype.$harbourData.post('/realtime/cell-lock-update', {
        column_id,
        parent_id,
        reference_id: asset_id,
        lock,
        request_type: 'column_lock',
      });
    },

    refreshColumnDefs(defs) {
      this.gridApi?.setColumnDefs(defs);
      this.gridApi?.refreshCells();
      this.workingColumnDefs = defs;
    },

    removeUserColumn(key) {
      const gridColumns = this.getColumnDefs;
      const matchIndex = gridColumns.findIndex((column) => column.field === key);
      if (!matchIndex) return;

      this.workingColumnDefs = gridColumns.filter((column) => column.field !== key);
      this.refreshColumnDefs(this.getColumnDefs);
      this.harbourStore.sendColumnChangeSync({ changeType: 'delete-column', newColumn: { field: key } });
    },

    updateFolderColumnState() {
      // Updates the folder's columnState when columns change.
      const folder = this.harbourStore.getCurrentFolder;
      folder.columnState = this.saveState();
    },

    getFolderColumnState(folderId) {
      const folder = this.harbourStore.myFolders.find((folder) => folder.id === folderId);
      if (!folder) return;
      return folder.columnState;
    },

    saveState() {
      const state = this.gridColumnApi.getColumnState();

      this.stateToSave = [];
      state.forEach((column) => {
        this.stateToSave.push({
          id: column.colId,
          width: column.width,
          hide: column.hide,
          ai: column.ai,
          artisanal_id: column.artisanal_id,
          fileUnder: column.fileUnder,
        });
      });

      this.userColumnsToSave = [];
      const defs = this.gridApi.getColumnDefs();
      defs.forEach((column) => {
        if (!column.userColumn) return;

        const colDef = {
          headerName: column.headerName,
          field: column.field,
          type: column.ai ? 'ai' : 'user',
          artisanal_id: column.artisanal_id,
          fileUnder: column.fileUnder,
        };

        this.userColumnsToSave.push(colDef);
      });

      return {
        state: this.stateToSave,
        userColumns: this.userColumnsToSave,
      };
    },

    restoreColumns(data = null) {
      const folder = this.harbourStore.getCurrentFolder;
      if (!folder) return;

      const { columnState } = folder;
      let { state, userColumns } = columnState;
      if (!userColumns || !userColumns.length) return;

      let colDefs = this.libraryStore.gridApi?.getColumnDefs();
      if (!colDefs) return;

      // If we are removing a column, filter it out here first.
      if (!!data && data.change === 'delete-column') {
        colDefs = colDefs.filter((column) => column.field !== data.changes[0]?.field);
        this.refreshColumnDefs(colDefs);
        return;
      }

      // Filter out the actions column
      const actions = colDefs.find((column) => column.field === 'actions');
      colDefs = colDefs.filter((column) => column.field !== 'actions');
      const updatedColDefs = [...colDefs];

      userColumns.forEach((column) => {
        const existingColumn = updatedColDefs.find((col) => col.field === column.field);

        let updatedColumn = existingColumn;
        if (!existingColumn) {
          const prompt = {
            value: column.headerName,
            field: column.field,
            artisanal_id: column.artisanal_id,
            columnType: column.type,
          };
          updatedColumn = this.createColumnFromPrompt(prompt);
          updatedColDefs.push(updatedColumn);
        } else existingColumn.headerName = column.headerName;
      });

      // Update the coldefs with the new columns
      updatedColDefs.push(actions);
      this.refreshColumnDefs(updatedColDefs);

      const initial = this.gridColumnApi.getColumnState();
      const restored = [];

      if (!state || !state.length) return;

      const actionsState = state.find((column) => column.id === 'actions');
      state = state.filter((column) => column.id !== 'actions');
      state.forEach((column) => {
        const existingColumn = initial.find((col) => col.colId === column.id);
        if (!existingColumn) return;

        existingColumn.width = column.width;
        existingColumn.hide = column.hide;
        restored.push(existingColumn);
      });

      // Ensure 'Actions' is always last
      restored.push(actionsState);
      this.gridColumnApi.applyColumnState({ state: restored, applyOrder: true });
      this.gridApi?.refreshCells();
    },

    generateDocumentTypePrompt(documentTypeId) {
      return [
        {
          columnType: 'ai',
          artisanal_id: 'document_type',
          value: 'Document type',
          source: 'artisanal',
          key: documentTypeId,
        },
      ];
    },

    addHideOptionToFilterPopup(evt) {
      if (evt.source !== 'COLUMN_MENU') return;

      const PopupHead = Vue.extend(GridFilterPopupHead);
      const popupHeadInstance = new PopupHead({
        propsData: {
          colId: evt.column.colId,
          gridApi: this.gridApi,
          gridColumnApi: this.gridColumnApi,
        },
      });
      popupHeadInstance.$mount();
      evt.eGui.offsetParent.prepend(popupHeadInstance.$el);
    },
  },
});
