import Vue from 'vue';
import { ModalProgrammatic as Modal } from 'buefy';
import { ToastProgrammatic as Toast } from 'buefy';
import HrbrAgreementLinkCreatedModal from '@/components/Modals/HrbrAgreementLinkCreated.vue';
import generalApiService from '@/services/general-api-service.js';
import {
  formatUnixTimestamp as formatUnixTimestampUtil,
  convertToUnixMillis as convertToUnixMillisUtil,
} from '@/utils/date-utils.js';

export default {
  async setLastCreatedAgreementLinkOptions() {
    const response = await Vue.prototype.$harbourData.post(
      '/data?agreement_editor_get_agreelink_last_selected_options',
      {
        requesttype: 'agreement_editor_get_agreelink_last_selected_options',
      },
    );
    if (response.data.last_created_agreelink) {
      const isReusableLink = response.data.last_created_agreelink.agreelink_auth_mode === 'PUBLIC';
      this.isAgreementEditorReusableLinkEnabled = isReusableLink;
    }
  },

  async getUserLastSelectedOptions() {
    try {
      const { data } = await Vue.prototype.$harbourData.post('/data?account-getsavedsettings', {
        requesttype: 'account-getsavedsettings',
      });
      this.processSavedSettings(data);
    } catch (e) {
      console.log('# Error retrieving account saved settings.');
    }
  },

  openAgreementLinkCreatedModal(link, parent, action) {
    Modal.open({
      parent,
      component: HrbrAgreementLinkCreatedModal,
      hasModalCard: true,
      props: {
        contextDict: this.contextDict,
        shareLink: link.url,
        shareLinkDisplayId: link.id,
        requestLinkTitle: link.title,
        action: action,
      },
    });
  },

  openLinkInEditor(linkObject = null, isSkipToCk = true, isApprovalFlow = false) {
    const agreementId = linkObject.agreement_id || linkObject.agreementId;
    const linkDisplayId = linkObject.id;
    const linkSigners = linkObject.email_recipients || linkObject.emailRecipients;
    const linkRecordTitle = linkObject.title;
    const documentTitle = linkObject.document_title || linkObject.documentTitle;

    const ckeditorFileId = linkObject.ckeditor_agreement_id || linkObject.ckeditorAgreementId;
    const currentFolderId = linkObject.folder;
    const creationMode = linkObject.template_group_id ? 'template' : 'agreement';

    const ckeditorProps = {
      ckeditorFileId,
      isSkipToCk,
      currentFolderId,
    };

    let props = {
      creationMode,
      agreementId,
      linkDisplayId,
      linkSigners,
      linkRecordTitle,
      documentTitle,
      parent: linkObject.parent,
      linkItem: linkObject,
      isEditingApprovalFlowLink: isApprovalFlow,
    };
    if (ckeditorFileId) props = { ...props, ...ckeditorProps };

    Vue.prototype.$openAgreementEditorModal({ props });
  },

  async updateUserSettings(settingstoupdate) {
    const options = {
      requesttype: 'account-updatesavedsettings',
      settingstoupdate,
    };
    return await Vue.prototype.$harbourData.post('/data?account-updatesavedsettings', options);
  },

  resetSectionHeader() {
    this.sectionHeader = {};
  },

  async cloneLink(linkItem, isTemplate = false) {
    // Migrated from vue-app-library
    // Cloning a link - separate logic between CK and PDF
    // Returns the response.data and expects the caller to handle the response accordingly
    let ckeditorFileId = linkItem.ckeditorFileId;
    let agreementId = linkItem.agreementId;

    let response;
    if (ckeditorFileId) {
      response = await this.ckeditorStore.cloneCkeditorLink({
        ckeditorFileId,
        agreementId,
        linkItem,
      });
    } else {
      // Previous workflow for copying - for PDFs only.
      response = await Vue.prototype.$harbourData.post(
        '/data?agreement_editor_copy_agreement_template',
        {
          requesttype: 'agreement_editor_copy_agreement_template',
          agreementid: linkItem.client_importbyurl_agreementid,
          filedisplayid: linkItem.client_importbyurl_custominputvaluesjsonstr.ckeditorAgreementId,
          parent: this.currentFolderId,
          istemplate: isTemplate,
        },
      );
      response.data.updatedAgreementId = response.data.copiedagreementid;
    }

    //Validate: valid return message
    if (response.data.state !== 'SUCCESS') {
      alert(
        'Not able to copy as agreement/template at this time. Try again in a bit or contact suppport@harbourshare.com',
      );
      return false;
    }

    //Success message
    Toast.open({
      duration: 2500,
      message: 'Success - copied as ' + (isTemplate ? 'a template' : 'agreement'),
      position: 'is-top',
      type: 'is-success',
    });

    return response.data;
  },

  async changeAgreementPublishSettings(templateId, templateGroupId, ckeditorFileId = null) {
    // Migrated from vue-app-library
    // Used when cloning a link to a template
    const isTemplate = true;

    const response = await Vue.prototype.$harbourData.post(
      '/data?agreement_editor_publish_agreement',
      {
        requesttype: 'agreement_editor_publish_agreement',
        templateid: templateId,
        is_template: isTemplate,
        template_group_id: templateGroupId,
      },
    );

    if (response.data.state !== 'SUCCESS') {
      alert(
        'Changing template publish settings failed, Try again later or contact support@harbourshare.com',
      );
      return false;
    }
    return true;
  },

  capitalizeText(text) {
    if (text) return text[0].toUpperCase() + text.slice(1);
    return text;
  },

  convertToUnixMillis(date) {
    return convertToUnixMillisUtil(date);
  },

  formatUnixTimestamp(dateData) {
    return formatUnixTimestampUtil(dateData);
  },

  autosizeGridByReference(gridReference, gridApi, gridColumnApi) {
    // Resize the dashboard according to column width percentages to maintain aspect ratio based on
    // User settings, if any
    const gridElement = gridReference?.$el;

    const visibleColumns = gridColumnApi?.getAllDisplayedColumns();
    if (!visibleColumns || !gridElement) return;
    const skipColumns = visibleColumns
      .filter((column) => column.minWidth === column.maxWidth)
      .map((column) => column.colId);

    // Get the widths
    const visibleWidth = visibleColumns.reduce((acc, column) => acc + column.actualWidth, 0);
    const staticColumns = visibleColumns.filter((column) => skipColumns.includes(column.colId));
    const staticWidth = staticColumns.reduce((acc, column) => acc + column.actualWidth, 0);
    const availableWidth = gridElement.getBoundingClientRect().width - 20;

    // Get a list of resizeable columns
    const columnsToResize = visibleColumns.filter((column) => !skipColumns.includes(column.colId));
    const newSizes = [];
    columnsToResize.forEach((column) => {
      const widthPercent = column.actualWidth / (visibleWidth - staticWidth);
      const newWidth = (availableWidth - staticWidth) * widthPercent;
      const newColWidth = {
        key: column,
        newWidth,
      };
      newSizes.push(newColWidth);
    });
    gridColumnApi.setColumnWidths(newSizes);
  },

  async getAwaitingMyReview() {
    if (this.isAwaitingMyReviewLoading) return;
    this.isAwaitingMyReviewLoading = true;
    const result = await Vue.prototype.$harbourData.get('/core/awaiting-my-review');
    this.isAwaitingMyReviewLoading = false;
    if (result.status !== 200) return;
    this.myPendingContracts = result.data?.data;
    this.isAwaitingMyReviewReady = true;
  },

  async getMySignedAgreements() {
    if (this.isSignedByMeLoading) return;
    this.isSignedByMeLoading = true;
    const result = await Vue.prototype.$harbourData.get('/core/signed-by-me');
    this.isSignedByMeLoading = false;
    if (result.status !== 200) {
      this.log('ERROR: getMySignedAgreements()', result);
      return;
    }

    const agreements = result.data?.data || [];
    if (this.mySignedAgreementsHbrefSearch) {
      const idx = agreements.findIndex(
        (i) => i.agreement_reference_id === this.mySignedAgreementsHbrefSearch,
      );
      if (idx !== -1) {
        const hbrefElem = agreements[idx];
        agreements.splice(idx, 1);
        agreements.splice(0, 0, hbrefElem);
      }
    }

    this.mySignedAgreements = agreements;
    this.isMySignedAgreementsReady = true;
  },

  checkUserAuthPermissions(actionPermessions) {
    if (actionPermessions.length == 0) return true;
    const permissions = this.contextDict?.auth_permissions ?? [];
    return actionPermessions.every((i) => permissions.includes(i));
  },

  //Get/store vision tags
  getImageVisionTags(assetdisplay_displayid, successCallback) {
    console.log('Requesting: assetbot-image-addgooglevisiontags - starting...');

    Vue.prototype.$harbourData
      .post(
        'https://us-central1-harbour-prod-webapp.cloudfunctions.net/assetbot-image-addgooglevisiontags',
        {
          requesttype: 'assetbot-image-addgooglevisiontags',
          assetdisplayid: assetdisplay_displayid,
        },
      )
      .then((response) => {
        console.log('-- assetbot-image-addgooglevisiontags: done');
        successCallback();
      })
      .catch((error) => {
        console.log('-- assetbot-image-addgooglevisiontags: failed', error);
      });
  },

  //Get/store vision web matches
  getImageVisionWebMatches(assetdisplay_displayid, successCallback) {
    console.log('Requesting: assetbot-image-addgooglevisionwebmatches - starting...');

    Vue.prototype.$harbourData
      .post(
        'https://us-central1-harbour-prod-webapp.cloudfunctions.net/assetbot-image-addgooglevisionwebmatches',
        {
          requesttype: 'assetbot-image-addgooglevisionwebmatches',
          assetdisplayid: assetdisplay_displayid,
        },
      )
      .then((response) => {
        console.log('-- assetbot-image-addgooglevisionwebmatches: done');
        successCallback();
      })
      .catch((error) => {
        console.log('-- assetbot-image-addgooglevisionwebmatches: failed', error);
      });
  },

  getVisualFingerprint(assetdisplay_displayid, successCallback) {
    console.log('Requesting: assetbot-image-addvisualfingerprint - starting...');

    Vue.prototype.$harbourData
      .post(
        'https://us-central1-harbour-prod-webapp.cloudfunctions.net/assetbot-image-addvisualfingerprint',
        {
          requesttype: 'assetbot-image-addvisualfingerprint',
          assetdisplayid: assetdisplay_displayid,
        },
      )
      .then((response) => {
        console.log(response);
        console.log('-- assetbot-image-addvisualfingerprint: done');
        successCallback();
      })
      .catch((error) => {
        console.log('-- assetbot-image-addvisualfingerprint: failed', error);
      });
  },

  getVideoIntelligenceTags: function (assetdisplay_displayid, successCallback) {
    console.log('Requesting: assetbot-video-addgooglevideointelligencetags - starting...');

    Vue.prototype.$harbourData
      .post(
        'https://us-central1-harbour-prod-webapp.cloudfunctions.net/assetbot-video-addgooglevideointelligencetags',
        {
          requesttype: 'assetbot-video-addgooglevideointelligencetags',
          assetdisplayid: assetdisplay_displayid,
        },
      )
      .then((response) => {
        console.log(response);
        console.log('-- assetbot-video-addgooglevideointelligencetags: done');
        successCallback();
      })
      .catch((error) => {
        console.log('-- assetbot-video-addgooglevideointelligencetags: failed', error);
      });
  },
  getRandomColor(attempt = 0) {
    // Set some preferred colors to choose from
    const preferredColors = [
      '#4d79a8',
      '#f28e2c',
      '#e15758',
      '#76b8b2',
      '#58a24e',
      '#edc949',
      '#b07aa1',
      '#ff9da8',
      '#9c745f',
      '#bab0ab',
    ];

    // Get a list of how many have been used
    const currentColors = Object.values(this.realtimeUserColors);
    const numOfColors = currentColors.length;

    // If we can use a preferred color, lets use it.
    if (numOfColors < preferredColors.length - 1) {
      return preferredColors[numOfColors];
    }

    // If we have more than 10 colors used already, we go to the randomizer.
    const r = Math.floor(Math.random() * 200);
    const g = Math.floor(Math.random() * 200);
    const b = Math.floor(Math.random() * 200);
    const color = `rgba(${r}, ${g}, ${b}, 0.7)`;
    if (currentColors.includes(color) && attempt < 10) return this.getRandomColor(attempt + 1);
    return color;
  },

  formatDateWithoutTime(dateObj) {
    if (!dateObj) return;
    try{
      const processed_date = new Date(dateObj);

      // Use Intl.DateTimeFormat to get the localized month name
      const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(processed_date);

      const day = processed_date.getDate();
      const year = processed_date.getFullYear();
      const formattedDate = `${month} ${day} ${year}`;
      return formattedDate;
    } catch (e) {
      return dateObj;
    }
  },

  async updateContextUserGroupsData() {
    const data = await generalApiService.getContextDict();
    this.contextDict = {
      ...this.contextDict,
      auth_groups: data.auth_groups,
      auth_roles: data.auth_roles,
    };
  },

  resetHTMLPageTitle() {
    document.title = 'Harbour';
  },

  updateHTMLPageTitle(title) {
    const suffix = 'Harbour';

    if (!title?.trim()) {
      return;
    }

    const pageTitle = `${title} - ${suffix}`;
    document.title = pageTitle;
    this.currentHTMLPageTitle = pageTitle;
  },

  prepareForBookmark({title, bookmark}) {
    this.updateHTMLPageTitle(title);
    this.updateUserLocationQuery({ bookmark });

    const url = new URL(window.location.href);
    url.searchParams.set('bookmark', JSON.stringify(bookmark));
    window.history.replaceState({}, '', url.toString());
  },

  resetPrepareForBookmark() {
    this.resetHTMLPageTitle();
    this.resetUserLocationQuery();
 
    const url = new URL(window.location.href);
    url.searchParams.delete('bookmark');
    window.history.replaceState({}, '', url.toString());
  },

  prepareForSuperdocBookmark({title, id}) {
    const bookmark = { superdocid: id }
    this.prepareForBookmark({title, bookmark});
  }
};
