import Vue from 'vue';
import { CK_BUNDLE_VERSION } from '@/components/Ckeditor/lib/bundle-version';

export default {
  async loadMetadata(fileDisplayId) {
    const res = await Vue.prototype.$harbourData.post('/documentsharing-metadata-load', {
      filedisplayid: fileDisplayId,
    });
    return res.data;
  },

  async saveMetadata({ fileDisplayId, refids = {}, suggestions = [], comments = [] }) {
    const params = {
      filedisplayid: fileDisplayId,
      refids,
      suggestions,
      comments,
      ckeditorversion: import.meta.env.VITE_CKEDITOR_BUNDLE_VERSION,
    };
    const resp = await Vue.prototype.$harbourData.post('/documentsharing-metadata-save', params);
    return resp.data;
  },

  async syncDocumentMetadata({ fileDisplayId, suggestions = [], comments = [] }) {
    const url = `document-sync-metadata/${fileDisplayId}`;
    const res = await Vue.prototype.$harbourData.post(url, {
      suggestions,
      comment_threads: comments,
    });
    return res.data;
  },

  async updateDraftHtml({ fileDisplayId, html }) {
    const url = '/agreelinkdata?agreelinkdata-ckeditordrafthtmlupdate';
    const res = await Vue.prototype.$harbourData.post(url, {
      requesttype: 'agreelinkdata-ckeditordrafthtmlupdate',
      filedisplayid: fileDisplayId,
      html,
    });
    return res.data;
  },

  async saveDocumentSharing({
    fileDisplayId,
    writers = [],
    commentators = [],
    readers = [],
    message = '',
    skipnotify = false,
    documentTitle = 'Untitled',
    linkDisplayId = null,
  }) {
    const params = {
      filedisplayid: fileDisplayId,
      writers,
      commentators,
      readers,
      skipnotify,
      documenttitle: documentTitle,
      linkdisplayid: linkDisplayId,
    };
    if (message) params.message = message;

    const res = await Vue.prototype.$harbourData.post('/documentsharing-save', params);
    return res.data;
  },

  async loadDocumentSharing({ fileDisplayId, isGetInitialData = false, linkDisplayId = null}) {
    const res = await Vue.prototype.$harbourData.post('/documentsharing-load', {
      filedisplayid: fileDisplayId,
      getinitialdata: isGetInitialData,
      link_display_id: linkDisplayId,
    });
    return res.data;
  },

  async lockCollaboration({ fileDisplayId, isCollabEnabled }) {
    const res = await Vue.prototype.$harbourData.post('/data?documentsharing-lockcollaboration', {
      requesttype: 'documentsharing-lockcollaboration',
      fileDisplayId,
      isCollabEnabled,
    });
    return res.data;
  },

  async loadDocumentSettings(fileDisplayId, linkDisplayId = null) {
    let url = `/document-settings/${fileDisplayId}`;
    if (linkDisplayId) url += `?link_display_id=${linkDisplayId}`;
    const res = await Vue.prototype.$harbourData.get(url, null, null, {
      withCredentials: true,
    });
    return res.data;
  },

  async getTemplateHtml(templateId, linkId = null) {
    const res = await Vue.prototype.$harbourData.post('/data?template_ckeditor_html', {
      requesttype: 'template_ckeditor_html',
      templateId,
      linkid: linkId
    });
    return res.data;
  },

  async storeFileVersionAndDocumentMetadata({
    fileVersionDisplayId,
    comments,
    suggestions,
    isOriginalFile,
    fileName,
    fileSizeBytes,
    ancestorDisplayId,
    fileBase64,
    inputMethod,
  }) {
    const url = '/data?store_file_version_and_document_metadata';
    const resp = await Vue.prototype.$harbourData.post(url, {
      requesttype: 'store_file_version_and_document_metadata',
      filedisplayid: fileVersionDisplayId, // id of superdoc
      comments, // []
      suggestions, // []
      comment_threads: comments, // []
      isoriginalfile: isOriginalFile,
      filename: fileName,
      filesizebytes: fileSizeBytes,
      fileancestordisplayid: ancestorDisplayId,
      filebase64: fileBase64, // null
      inputmethod: inputMethod,
      ckeditorversion: CK_BUNDLE_VERSION,
    });
    return resp.data;
  },

  async importFromWord({ fileName, fileBase64, fileVersionDisplayId }) {
    const url = '/agreementeditor-importfromword';
    const payload = {
      filename: fileName,
      filebase64: fileBase64,
    };
    if (fileVersionDisplayId) payload.file_display_id = fileVersionDisplayId;

    const resp = await Vue.prototype.$harbourData.post(url, payload);
    return resp.data;
  },

  async storeVersionedHtml({ fileVersionDisplayId, updatedHtml }) {
    const url = '/data?agreementeditor-storeversionedhtml';
    const payload = {
      requesttype: 'agreementeditor-storeversionedhtml',
      filedisplayid: fileVersionDisplayId,
      updatedhtml: updatedHtml,
    };

    const resp = await Vue.prototype.$harbourData.post(url, payload);
    return resp.data;
  },

  async storeCkHtmlUpdate({
    linkDisplayId,
    ckAgreementId,
    updatedHtml,
    customInputs,
  }) {
    const url = '/agreelinkdata?agreelinkdata-storeckeditorhtmlupdate';

    const payload = {
      requesttype: 'agreelinkdata-storeckeditorhtmlupdate',
      linkdisplayid: linkDisplayId,
      ckeditoragreementid: ckAgreementId,
      updatedhtml: updatedHtml,
    };
    if (customInputs) payload.custominputsjson = customInputs;

    const resp = await Vue.prototype.$harbourData.post(url, payload);
    return resp.data;
  },
};
