import { ref } from 'vue';
import { DraftMainGroups } from '@/domain/drafts/draft';

// contract for draft data + ui properties
export function useDraft(draft, opts = {}) {
  const id = ref(draft.id);
  const orgId = ref(draft.orgId);
  const name = ref(draft.name);
  const ckFileId = ref(draft.ckFileId);
  const createdAt = ref(draft.createdAt);
  const createdBy = ref(draft.createdBy);
  const updatedAt = ref(draft.updatedAt);
  const updatedBy = ref(draft.updatedBy);
  const agreementId = ref(draft.agreementId);
  const status = ref(draft.status);
  const copiedFrom = ref(draft.copiedFrom);
  const isShared = ref(draft.isShared);
  const role = ref(draft.role);
  const isCopy = ref(draft.isCopy);
  const type = ref(draft.type);
  const reader = ref(draft.reader);
  const writer = ref(draft.writer);
  const commentator = ref(draft.commentator);
  const collaboratorsWithImmutableRoles = ref(draft.collaboratorsWithImmutableRoles);
  const collaboratorsWithInheritedRoles = ref(draft.collaboratorsWithInheritedRoles);
  const owner = ref(draft.owner);
  const state = ref(draft.state);

  // UI
  const draftPath = ref([]);
  const buildPath = () => {
    const path = [id.value];
    if (isShared.value) path.unshift(DraftMainGroups.SharedDrafts);
    else path.unshift(DraftMainGroups.MyDrafts);
    return path;
  };
  const setPath = () => {
    const path = buildPath();
    draftPath.value = path;
  };

  const isPlaceholder = ref(false);
  const setPlaceholderDraft = (value) => {
    isPlaceholder.value = value;
  };

  return {
    id,
    orgId,
    name,
    ckFileId,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    agreementId,
    status,
    copiedFrom,
    isShared,
    role,
    isCopy,
    type,
    reader,
    writer,
    commentator,
    collaboratorsWithImmutableRoles,
    collaboratorsWithInheritedRoles,
    owner,
    state,

    // UI
    draftPath,
    isPlaceholder,
    setPath,
    setPlaceholderDraft,
  };
}
