export const formatUnixTimestamp = (dateData) => {
  // New global date function to follow new format

  const unixTimestamp = convertToUnixMillis(dateData);
  let epochMs = unixTimestamp;
  if (epochMs === 'NOW') epochMs = Date.now();
  if (typeof epochMs === 'string') epochMs = parseInt(epochMs);
  try {
    // Date
    const eventDate = new Date(epochMs);
    const newDate = eventDate.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    const newDateWithoutComma = newDate.replace(',', '');

    // Time
    const eventTime = new Date(epochMs);
    const newTime = eventTime.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
    return `${newDateWithoutComma} ${newTime}`;
  } catch (err) {
    return '-';
  }
};

export const convertToUnixMillis = (date) => {
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{6}\+\d{2}:\d{2}$/;
  if (regex.test(date)) {
    const dtObj = new Date(date);
    const unixMillis = dtObj.getTime();
    return unixMillis;
  } else if (typeof date === 'string') {
    return parseInt(date);
  }
  return date;
};
